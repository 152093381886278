import React from 'react'
import './IndexForm.scss'

import {Container, Row, Col} from 'react-bootstrap'
import MailIcon from '../../../static/svg/mail.svg'
import ScrollAnimation from 'react-animate-on-scroll';
import { navigate } from 'gatsby-link';
import PhoneInput from 'react-phone-number-input'

function encode(data) {
    const formData = new FormData()
  
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
  
    return formData
}

export default function InderForm({isWhite, mail}) {
    const [state, setState] = React.useState({})
    const [value, setValue] = React.useState()

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleAttachment = (e) => {
        setState({ ...state, [e.target.name]: e.target.files[0] })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        fetch('/', {
            method: 'POST',
            body: encode({
                'form-name': form.getAttribute('name'),
                'subject': 'Заявка с сайта Дерево Желаний',
                ...state,
            }),
        })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error))
    }

    

    return (
        <section className={isWhite ? "index-form_white" : 'index-form'}>
            <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
            <Container className="index-form--wrapper" fluid="xl">
                <Row className="justify-content-center">
                    <Col className='index-form--section' sm={12} md={6} >
                        <h2 className="index-page--title index-form--title">Есть готовый проект?</h2>
                        <p className="index-form--sign">Присылайте его нам, и мы свяжемся с вами в ближайшее время</p>
                        
                    </Col>
                    <div className="index-form--br"></div>
                    <Col className='justify-content-center index-form--section'>
                        <form 
                        name="request-on-page" 
                        method="post" 
                        action="/success" 
                        className="index-form--form form" 
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                        >
                            
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="request-on-page" />
                            <div hidden>
                            <label>
                                Don’t fill this out:{' '}
                                <input name="bot-field" onChange={handleChange} />
                            </label>
                            </div>
                            
                            <input type="hidden" id="subject" name="subject" value="Заявка с сайта Дерево Желаний" onChange={handleChange}/>
                            {/* <input type="hidden" id="subject" name="subject" value="Zayavka s saita Derevo Jelaniy" onChange={handleChange}/> */}
                            <p className="form--group">
                                <label htmlFor="formName" className="visually-hidden">Имя*</label>
                                <input name="name" id='formName' type="text" className="form--input" placeholder='Имя*' required onChange={handleChange} />
                            </p>

                            <p className="form--group">
                                <label htmlFor="formTel" className="form--label">Телефон*</label>
                                <PhoneInput
                                placeholder="+7 (999) 999-99-99"
                                className="form--input form--input_tel" 
                                type={'tel'} 
                                name={'phone'} 
                                id={'formTel'} 
                                value={value}
                                onChange={setValue}
                                onInput={handleChange}
                                autoComplete
                                required
                                />
                            </p>

                            <p className="form--group">
                                <label htmlFor="formEmail" className="visually-hidden">Email</label>
                                <input name="email" id='formEmail' type="email" className="form--input" placeholder='Email' onChange={handleChange} />
                            </p>

                            <p className="form--group form--group_comment">
                                <label htmlFor="formComment" className="form--label">Комментарий</label>
                                <textarea name="comment" className="form--textarea" id="formComment" rows='3' onChange={handleChange} ></textarea>
                            </p>

                            <p className="form--group form--group_files">
                                <label htmlFor="attachFiles" className="form--label">Прикрепить файлы</label>
                                <input type="file" name="files"  id="attachFiles" onChange={handleAttachment}/>
                                <p className="form--small-text">Если вы хотите отправить более 1 файла, пожалуйста, напишите письмо напрямую на почту <a href={`mailto:${mail}`}>{mail}</a></p>
                            </p>

                            <button type="submit" className="form--submit button">Связаться с нами <img className='form--submit-icon' src={MailIcon} alt="Конверт"/></button>
                        </form>
                    </Col>
                </Row>

            </Container>
            </ScrollAnimation>
        </section>
    )
}

